.wrapper {
    display: flex;
    box-sizing: border-box;
    flex-direction: column;
    padding: 0;
    padding-top: 8px;
    padding-bottom: 8px;
    border: 1px solid rgba(var(--time-webkit-color-secondary-rgb), 0.16);
    margin: 0;
    background-color: rgb(var(--time-webkit-color-background-rgb));
    border-radius: 8px;
    box-shadow: 0 8px 24px rgba(0, 0, 0, 0.12);
}

.option {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0;
    padding: 8px 20px;
    margin: 0;
    background-color: rgb(var(--time-webkit-color-background-rgb));
    cursor: pointer;
    list-style: none;
    outline: none;
    text-decoration: none;
    transition: background-color 150ms ease-in-out;
}

.optionHover {
    background-color: rgba(var(--time-webkit-color-secondary-rgb), 0.08);
}

.optionFocus {
    border-radius: 4px;
    outline: 2px solid rgba(var(--time-webkit-color-main-rgb), 0.5);
}

.optionSelected {
    background-color: rgba(var(--time-webkit-color-main-rgb), 0.08);
}
