.centered_container {
    display: flex;
    height: 100vh;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.heading {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;
    margin: 0;
    margin-bottom: 12px;
    font-weight: 600;
    text-align: center;
}

.body {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0;
    margin: 0;
    gap: 4px;
    text-align: center;
}

.outerWrapper {
    display: flex;
    min-height: 100vh;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: rgb(var(--time-webkit-color-background-rgb, 255, 255, 255));
}

.innerWrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.text {
    color: rgba(var(--time-webkit-color-secondary-rgb), 0.96);
}
