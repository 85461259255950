.avatar {
    min-width: 24px;
    margin-right: 8px;
}

.overflow {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.maxWidth {
    max-width: 100px;
}

.tag {
    flex-shrink: 0;
    padding: 2px 0;
    color: rgba(var(--center-channel-color-rgb), 0.72);
}

.tagMargin {
    margin-left: 8px;
}

.row {
    display: flex;
    align-items: center;
    padding: 6px 16px;
}

.row:hover {
    background: rgba(var(--center-channel-color-rgb), 0.08);
    cursor: pointer;
}

.fullName {
    flex-shrink: 1;
    padding: 2px 0;
}
