.bar {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 8px;
    border-bottom-left-radius: var(--editor-border-radius);
    border-bottom-right-radius: var(--editor-border-radius);
    gap: 2px;

    --btn-size: 32px;
}

.button {
    width: var(--btn-size) !important;
    height: var(--btn-size) !important;
}

.divider {
    display: flex;
    height: var(--btn-size);
    align-items: center;
    justify-content: center;
    margin: 0 2px;
    color: rgba(var(--time-webkit-color-secondary-rgb), 0.16);
}

.divider svg {
    width: 1px;
    height: 16px;
}
