.lockScreen {
    position: relative;
    display: flex;
    width: 100vw;
    height: 100vh;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 12px;
    background-color: rgb(var(--time-webkit-color-secondary-rgb), 0.04);
}

.lockScreen::before {
    position: absolute;
    z-index: -1;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgb(var(--time-webkit-color-background-rgb));
    content: '';
}

.mobileLockScreen {
    padding: 24px;
}

.icon {
    font-size: 80px;
    line-height: 80px;
}

.iconMobile {
    font-size: 64px;
    line-height: 64px;
}

.title {
    margin: 24px 0 0 0;
    text-align: center;
}

.description {
    max-width: 580px;
    margin: 12px 0 24px 0;
    text-align: center;
}

.description.descriptionMobile {
    margin: 8px 0 24px 0;
    color: rgba(var(--center-channel-text-rgb), 0.72);
}

.bottom {
    margin-top: auto;
}

.top {
    margin-top: auto;
}

.actionButton {
    position: absolute;
    top: 16px;
}

.leftActionButton {
    left: 16px;
}

.rightActionButton {
    right: 16px;
}
