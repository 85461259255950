.button {
    --icon-button-size: 28px;
    --icon-button-padding: 2px;
    --icon-button-border-radius: 4px;
    --icon-button-color-rgb: var(--time-webkit-color-secondary-rgb);
    --icon-button-hover-bg-color-rgb: var(--time-webkit-color-secondary-rgb);
    --icon-button-active-bg-color-rgb: var(--time-webkit-color-main-rgb);

    position: relative;
    display: flex;
    width: var(--icon-button-size);
    height: var(--icon-button-size);
    box-sizing: border-box;
    align-items: center;
    justify-content: center;
    padding: var(--icon-button-padding);
    border: none;
    background: transparent;
    border-radius: var(--icon-button-border-radius);
    color: rgb(var(--icon-button-color-rgb), 0.56);
    cursor: pointer;
    text-align: center;
}

.button:hover {
    background-color: rgb(var(--icon-button-hover-bg-color-rgb), 0.08);
    text-decoration: none;
}

.button:active {
    background: rgb(var(--icon-button-active-bg-color-rgb), 0.08);
}

.hideFocus {
    outline: none;
}
