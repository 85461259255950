.container {
    padding: 4px;
    background-color: rgb(var(--dnd-indicator-rgb));
    border-radius: 4px;
    color: rgb(var(--sidebar-text-rgb));
    cursor: default;
}

.tooltipText {
    max-width: 100px;
}
