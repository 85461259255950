.root {
    display: flex;
    flex-direction: column;
    padding: 8px 0;
    border: 1px solid rgb(var(--time-webkit-color-secondary-rgb), 0.16);
    margin-top: 6px;
    background-color: rgb(var(--time-webkit-color-background-rgb));
    box-shadow: 0 8px 24px 0 rgb(0, 0, 0, 0.12);
    overflow-y: auto;
    overscroll-behavior-y: contain;
}

.medium {
    border-radius: 8px;
}

.large {
    border-radius: 10px;
}
