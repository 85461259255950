.mentionWrapper {
    display: grid;
    min-width: 150px;
    grid-column-gap: 16px;
    grid-row-gap: 3px;
    grid-template-columns: 20px 1fr;
    grid-template-rows: 1fr 1fr;
}

.icon {
    align-self: center;
    grid-column: span 1;
    grid-row: span 2;
}

.groupIcon {
    width: 20px;
    height: 20px;
    color: rgba(var(--time-webkit-color-secondary-rgb), 0.64);
}

.groupIcon svg {
    width: 20px;
    height: 20px;
}

.handle {
    color: inherit;
}

.description {
    color: rgba(var(--time-webkit-color-secondary-rgb), 0.64);
}

.description:empty {
    display: none;
}
