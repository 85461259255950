@charset 'UTF-8';

a {
    color: $primary-color;
    cursor: pointer;
    text-decoration: none;
    word-break: break-word;

    &:hover,
    &:focus {
        color: $primary-color--hover;
        outline: 0;
    }
}

.text-danger {
    color: desaturate($red, 20%);

    &:hover,
    &:focus {
        color: desaturate($red, 20%);
    }
}

.app__body .mention-link,
.app__body .mention--highlight {
    display: inline-block;
    padding: 0 4px;
    border-radius: 4px;
    line-height: 19px;

    .mention-link,
    .mention--highlight {
        padding: 0;
        line-height: 1;
    }

    & * {
        background: transparent !important;
        color: inherit !important;
    }
}

.app__body .mention-link {
    background-color: rgb(var(--time-webkit-color-main-rgb), 0.14);
    color: rgb(var(--time-webkit-color-main-rgb));
    cursor: pointer;

    &:hover {
        background-color: rgb(var(--time-webkit-color-main-rgb), 0.2);
        color: rgb(var(--time-webkit-color-main-rgb));
        text-decoration: none;
    }
}

.app__body .mention--highlight {
    background-color: rgb(var(--time-webkit-color-mention-highlight-bg-rgb));
    color: rgb(var(--time-webkit-color-mention-highlight-link-rgb));

    &.current-mention--highlight {
        color: rgb(var(--sys-denim-center-channel-text));
    }

    &:hover {
        background: linear-gradient(rgb(var(--time-webkit-color-mention-highlight-bg-rgb)), rgb(var(--time-webkit-color-mention-highlight-bg-rgb))), rgba(255, 255, 255, 0.3);
        background-blend-mode: lighten;
        color: rgb(var(--time-webkit-color-mention-highlight-link-rgb));
        text-decoration: none;

        &.current-mention--highlight {
            color: rgb(var(--sys-denim-center-channel-text));
        }
    }
}
