.detailsArea {
    margin: var(--padding) 0;
}

.detailsArea:has(+ :global(.user-popover__actions):empty) {
    margin-bottom: 0;
}

.detailsAreaItem {
    margin-top: var(--padding);
}
