.base {
    box-sizing: border-box;
    border-width: 1px;
    border-style: solid;
    border-color: rgba(var(--border-color-rgb), 0.16);
    border-radius: 8px;
}

.day {
    --border-color-rgb: var(--time-webkit-color-secondary-rgb);
    --elevation-one-shadow-color: rgba(0, 0, 0, 0.08);
    --elevation-shadow-color: rgba(0, 0, 0, 0.12);
}

.night {
    --border-color-rgb: var(--time-webkit-color-secondary-rgb);
    --elevation-one-shadow-color: rgba(0, 0, 0, 0.32);
    --elevation-shadow-color: rgba(0, 0, 0, 0.48);
}

.one {
    box-shadow: 0 2px 3px var(--elevation-one-shadow-color);
}

.two {
    box-shadow: 0 4px 6px var(--elevation-shadow-color);
}

.three {
    box-shadow: 0 6px 14px var(--elevation-shadow-color);
}

.four {
    box-shadow: 0 8px 24px var(--elevation-shadow-color);
}

.five {
    box-shadow: 0 12px 32px var(--elevation-shadow-color);
}

.six {
    box-shadow: 0 20px 32px var(--elevation-shadow-color);
}
