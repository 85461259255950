.dropdown {
    overflow: hidden;
    padding-top: 0;
    padding-bottom: 0;
}

.content {
    width: 360px;
    max-width: 360px;
    max-height: 228px;
}

.fullWidth {
    width: 100%;
}

.center {
    display: flex;
    align-items: center;
    justify-content: center;
}

.error,
.subTitle,
.emptyMembers {
    color: rgba(var(--center-channel-color-rgb), 0.72);
}

.wrapper {
    display: block;
}

.error {
    display: block;
    padding: 20px 16px;
}

.emptyMembers {
    display: block;
    padding: 12px 16px 20px 16px;
    line-height: 20px;
}

.initialSpinner {
    padding: 16px 0 20px 0;
}

.header {
    padding: 14px 16px 5px;
}

.header h5 {
    margin: 2px 0 0;
}

.subTitle {
    display: flex;
    margin-top: 2px;
    margin-bottom: 2px;
}

.memberCount {
    flex-shrink: 0;
}

.overflow {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.divider {
    display: block;
    height: 1px;
}

.divider.divider_visible {
    background: rgba(var(--center-channel-color-rgb), 0.16);
}

.list {
    overflow: auto;
    width: 100%;
    max-height: 166px;
    scrollbar-width: thin;
}

.fetchMore {
    height: 32px;
    padding: 0 16px;
}

.lastPadding {
    padding: 4px;
}
