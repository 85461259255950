@charset "utf-8";

/* stylelint-disable selector-class-pattern */
.post-right__container,
.ThreadViewer {
    display: flex;
    flex-direction: column;

    .post-right-root-message {
        padding: 1em 1em 0;
    }

    .post-right-comments-container {
        position: relative;

        .post {
            &:hover {
                & + .Separator {
                    .separator__hr {
                        overflow: visible;

                        &::before {
                            position: absolute;
                            top: -16px;
                            left: 0;
                            width: 100%;
                            height: 16px;
                            background: rgba(v(center-channel-color-rgb), 0.04);
                            content: '';
                        }
                    }
                }
            }
        }
    }

    .help__format-text {
        display: none;
    }

    .post {
        &.post--root {
            border-bottom: 1px solid rgba(var(--center-channel-color-rgb), 0.12);
        }

        &.post--comment {
            .post__body {
                border-left: none;
            }
        }

        .post__content {
            padding: 0 10px 0 0;
        }

        .post__body {
            padding-left: 0;
        }

        &.post--compact {
            .post__img {
                display: none;
            }

            .post__content > * + * {
                margin-left: 0;
            }

            .post-menu {
                top: -12px !important;
            }
        }

        .post-preview .post__img {
            width: 24px;
        }
    }

    hr {
        border: none;
    }

    .msg-typing-container {
        padding-bottom: 3px;
    }

    .post-create__container {
        width: 100%;
        margin-top: 1.4em;

        .btn {
            margin-bottom: 10px;

            &.disabled {
                border-color: transparent !important;
                background: grey !important;
            }
        }

        .custom-textarea {
            min-height: 100px;
            max-height: calc(50vh - 80px);
        }

        .post-right-comments-upload-in-progress {
            padding: 6px 0;
            margin-right: 10px;
            opacity: 0.7;
        }

        .emoji-picker {
            position: absolute;
            top: -361px;
            right: 0;
        }

        form {
            padding: 0;
        }

        .post-create {
            margin: 0.5em 15px 0;
        }
    }
}

.post-right__container {
    flex: 1;

    .ThreadViewer {
        flex: 1;
    }
}

.post-right-header {
    height: 39px;
    padding: 10px 10px 0 15px;
    border-bottom: $border-gray;
    color: #999;
    font-size: 1em;
    font-weight: 400;
    text-transform: uppercase;
}

.post-right-root-container {
    padding: 5px 10px;
    border-bottom: $border-gray;

    ul {
        padding-left: 0;
        margin-bottom: 2px;
    }
}

.post-right-channel__name {
    margin: 8px 0 12px;
    font-weight: 600;
}

.card-info-channel__name {
    margin: 0 0 15px 14px;
    font-weight: 600;
}

.post-right-root-container li {
    display: inline;
    padding-right: 3px;
    list-style-type: none;
}

.post-right-root-time {
    color: #a8adb7;
}

.post-right-create-comment-container {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    padding: 5px;
    margin-bottom: 18px;
}

.post-right__scroll {
    position: relative;
    flex: 1 1 auto;
    padding-top: 28px;
    -webkit-overflow-scrolling: touch;

    .file-preview__container {
        margin-top: 5px;
    }
}

.post-right-comment-time {
    color: #a8adb7;
}
/* stylelint-enable selector-class-pattern */
