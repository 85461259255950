/* stylelint-disable selector-class-pattern */
.app__body .user-profile-popover {
    --padding: 16px;
    --gap: 12px;
    --gray: rgba(var(--center-channel-color-rgb), 0.56);

    min-width: 245px;
    max-width: 288px;
    border-radius: 8px;

    .popover-content {
        padding: var(--padding);
        font-size: 14px;
        line-height: 20px;

        > *:last-child {
            margin-bottom: 0 !important;
        }

        hr.divider {
            border-color: rgba(var(--center-channel-color-rgb), 0.08);
            margin: var(--padding) 0;
            margin-top: 0;

            &:first-child,
            &:last-child {
                display: none;
            }
        }

        .user-profile-popover__user-role {
            padding: 4px;
            margin: -16px -16px 16px;
            background: rgba(var(--center-channel-color-rgb), 0.06);
            border-radius: 7px 7px 0 0;
            font-size: 12px;
            line-height: 16px;
            text-align: center;

            &.contrast {
                background: var(--gray);
                color: var(--center-channel-bg);
            }

            .icon {
                display: inline-block;
                margin: -4px 4px 0 0;
                fill: currentColor;
                vertical-align: middle;
            }
        }

        .user-profile-popover__user-name {
            display: flex;
            align-items: center;
            padding: calc(var(--padding) / 2);
            margin: calc(var(--padding) / -2) calc(var(--padding) / -2) calc(var(--padding) / -2 + var(--padding));
            margin-bottom: 0;
            border-radius: 8px;

            > * + * {
                margin-left: var(--gap);
            }

            .status-wrapper {
                align-self: start;
            }

            .user-profile-popover__name-container {
                width: 100%;
            }

            .user-profile-popover__name {
                display: flex;
                font-size: 16px;
                font-weight: 600;
                gap: 8px;
                line-height: 24px;

                .Badge {
                    align-self: start;
                    margin-top: 4px;
                    margin-left: auto;
                    white-space: nowrap;
                }
            }

            .user-profile-popover__description {
                color: var(--gray);
                font-size: 12px;
                line-height: 16px;
            }
        }

        .user-profile-popover__user-name_clickable {
            cursor: pointer;

            &:hover {
                background: rgba(var(--center-channel-color-rgb), 0.08);
            }
        }

        .user-profile-popover__custom-status {
            margin: 6px 0;
            margin-bottom: 8px;

            + hr.divider {
                margin-top: 14px;
            }
        }

        .user-popover__actions:not(:empty) {
            display: flex;
            min-height: 32px;

            > * + * {
                margin-left: calc(var(--padding) / 2);
            }

            button {
                min-width: 44px;
                border: none;
                background: rgba(var(--button-bg-rgb), 0.08);
                border-radius: 4px;
                color: var(--button-bg);
                fill: var(--button-bg);

                &:hover {
                    background: rgba(var(--button-bg-rgb), 0.12);
                }

                > .icon {
                    margin-bottom: -1px;

                    &.messages {
                        margin-bottom: 2px;
                        vertical-align: middle;
                    }

                    + * {
                        margin-left: 4px;
                    }
                }

                &:first-child {
                    flex: 1;
                }

                &.primary {
                    background: var(--button-bg);
                    color: var(--button-color);
                    fill: var(--button-color);

                    &:hover {
                        background: linear-gradient(0deg, rgba(0, 0, 0, 0.08), rgba(0, 0, 0, 0.08)), var(--button-bg);
                    }
                }
            }
        }
    }
}

.app__body .user-profile-popover__tooltip {
    z-index: 100001;
}
