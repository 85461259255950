.codeBlockWraper {
    position: relative;
    display: flex;
}

.codeBlockRenderer {
    position: relative;
    width: 100%;
}

.codeBlock {
    position: relative;
    display: flex;
    width: 100%;
    min-height: 20px;
    padding: 8px 8px 8px 6px;
}

.codeBlock.langDetected {
    padding: 8px 8px 8px 46px;
}

.codeBlock > code[data-language]::after {
    position: absolute;
    top: 4px;
    right: 4px;
    padding: 4px;
    background-color: rgba(var(--time-webkit-color-secondary-rgb), 0.1);
    border-radius: 4px;
    color: rgba(var(--time-webkit-color-secondary-rgb), 0.64);
    content: attr(data-language);
    opacity: 0;
    pointer-events: none;
    text-transform: uppercase;
    transition: opacity 150ms ease-out;
}

.codeBlock:hover > code[data-language]::after {
    opacity: 1;
}

.code {
    padding-left: 2px;
    overflow-x: auto;
}

.lineNumbers {
    position: absolute;
    top: 14px;
    display: flex;
    min-width: 36px;
    flex-direction: column;
    align-items: center;
    padding: 0 6px;
    border-right: 1px solid rgba(var(--time-webkit-color-secondary-rgb), 0.64);
    color: rgba(var(--time-webkit-color-secondary-rgb), 0.64);
    font-size: 13px;
}
