.mentionWrapper {
    display: flex;
    min-width: 150px;
    align-items: center;
}

.icon {
    margin-right: 8px;
}

.groupIcon {
    width: 20px;
    height: 20px;
    color: rgba(var(--time-webkit-color-secondary-rgb), 0.64);
}

.groupIcon svg {
    width: 20px;
    height: 20px;
}

.handle:not(:last-child) {
    margin-right: 8px;
}

.description {
    color: rgba(var(--time-webkit-color-secondary-rgb), 0.64);
}

.description:empty {
    display: none;
}
