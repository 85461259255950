.destructiveInverted {
    --btn-border-width: 0;
}

.flatNotInverted,
.flatInverted {
    --btn-border-width: 0;
}

.flatNotInverted {
    color: rgba(var(--time-webkit-color-secondary-rgb), 0.56) !important;
}

.iconBtn-large {
    padding: 10px !important;

    --icon-margin: 9.5px;
}

.iconBtn-medium {
    padding: 10px !important;

    --icon-margin: 8.5px;
}

.iconBtn-small {
    padding: 8px !important;

    --icon-margin: 6px;
}

.iconBtn-xSmall {
    padding: 6px !important;

    --icon-margin: 5.5px;
}

.compactBtn-large {
    padding: 8px !important;

    --icon-margin: 9.5px;
}

.compactBtn-medium {
    padding: 8px !important;

    --icon-margin: 8.5px;
}

.compactBtn-small {
    padding: 6px !important;

    --icon-margin: 6px;
}

.compactBtn-xSmall {
    padding: 4px !important;

    --icon-margin: 5.5px;
}

.iconWrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.iconWrapper svg {
    width: 1.32em;
    height: 1.32em;
    margin-right: var(--icon-margin);
}

.iconWrapper svg:only-child {
    margin: 0;
}
