.editor {
    display: flex;
    width: 100%;
    min-height: 44px;
    box-sizing: border-box;
    flex-direction: column;
    align-items: flex-start;
    padding: 14px 12px;
    padding-bottom: 8px;
    margin: 0;
    background-color: rgb(var(--time-webkit-color-background-rgb));
    overflow-y: auto;
}

.editor:focus-visible {
    outline: none;
}

.editorWrapper {
    min-height: 136px;
    border: 1px solid rgba(var(--time-webkit-color-secondary-rgb), 0.16);
    border-radius: var(--editor-border-radius);
    transition: border-color 150ms ease-out;

    --editor-border-radius: 8px;
}

.editorWrapper.isFocused {
    border-color: rgba(var(--time-webkit-color-secondary-rgb), 0.32);
    transition-timing-function: ease-in;
}

.bodySlot:not(:empty) {
    display: flex;
    overflow: none;
    max-width: 100%;
    box-sizing: border-box;
    padding: 0;
    padding-right: 12px;
    padding-left: 12px;
    margin: 0;
}

.bodySlot:not(:empty) > div {
    width: auto;
    max-width: 100%;
    height: auto;
    box-sizing: border-box;
    padding: 0;
    margin: 0;
    overflow-x: auto;
}

.bodySlot:not(:empty) > div > div:last-child {
    margin-right: 0;
}

.editorOuterWrapper {
    display: flex;
    max-height: 256px;
    box-sizing: border-box;
    flex-direction: column;
    padding: 0.1px;
}

.limitCounterWrapper {
    position: relative;
}

.limitCounter {
    position: absolute;
    right: 14px;
    bottom: 4px;
}

.readOnly {
    display: flex;
    width: 100%;
    height: 48px;
    box-sizing: border-box;
    flex: none;
    flex-direction: column;
    flex-grow: 0;
    align-items: center;
    align-self: stretch;
    justify-content: center;
    order: 0;
    padding: 0;
    border: 1px solid rgba(var(--time-webkit-color-secondary-rgb), 0.16);
    margin: 0;
    background-color: rgb(var(--time-webkit-color-background-rgb));
    border-radius: 8px;
    color: rgba(var(--time-webkit-color-secondary-rgb), 0.64);
}

.image {
    width: calc(100% - 5px);
    min-width: 100px;
    max-width: 100%;
    max-width: 360px;
    min-height: 100px;
    max-height: 350px;
    border: 1px solid transparent;
    margin: 5px 2px;
    background-color: rgba(var(--time-webkit-color-secondary-rgb), 0.16);
    border-radius: 4px;
}

/* Placeholder (at the top) */
:global .ProseMirror p.is-editor-empty:first-child::before {
    height: 0;
    content: attr(data-placeholder);
    float: left;
    pointer-events: none;
}
