.root {
    display: inline-flex;
    align-items: center;
    padding: 0;
    border: 0 rgb(var(--time-webkit-color-main-rgb));
    background-color: transparent;
    cursor: pointer;
    text-decoration: none;
}

.root.default,
.root.flat,
.root.pseudo {
    color: rgb(var(--time-webkit-color-main-rgb));
}

.root.clear {
    color: rgb(var(--time-webkit-color-secondary-rgb), 0.72);
}

.root:hover {
    filter: brightness(0.72);
    text-decoration: none;
}

.root.clear:hover {
    color: rgb(var(--time-webkit-color-secondary-rgb));
}

.root.pseudo:not(.flat) .text {
    text-decoration: 1px dashed underline;
    text-underline-offset: 0.2em;
}

.icon,
.iconRight {
    font-size: 0;
    line-height: 0;
}

.icon {
    margin-right: 4px;
}

.iconRight {
    margin-left: 4px;
}
