.modal {
    .about-modal.about-modal {
        width: 428px;
        font-size: 14px;
        line-height: 20px;
        text-align: center;

        .modal-content {
            border-radius: 12px;
        }

        .modal-body {
            padding: 26px;
        }

        .modal-header {
            min-height: 0;
            align-items: start;
            padding: 0;
            border: none;
            background: transparent;

            .close {
                top: 15px;
                right: 15px;
                color: rgb(var(--time-webkit-color-secondary-rgb), 0.64);
                font-size: 30px;
                font-weight: normal;
                opacity: 1;

                &:hover {
                    background: inherit;
                }
            }
        }

        .about-modal__logo {
            display: flex;
            justify-content: center;
            line-height: 0;

            svg {
                width: 62px;
                height: 62px;
            }
        }

        .about-modal__title {
            margin: 16px 0 0;
            font-size: 20px;
            line-height: 28px;
        }

        .about-modal__version {
            margin: 4px 0 0;
            color: rgb(var(--center-channel-color-rgb), 0.72);
        }

        .about-modal__footer {
            margin: 32px 0 12px;

            > * {
                position: relative;
                display: inline-block;
                vertical-align: middle;

                & + * {
                    margin-left: 16px;

                    &::before {
                        position: absolute;
                        top: 4px;
                        bottom: 3px;
                        left: -8px;
                        display: block;
                        width: 1px;
                        background-color: rgb(var(--center-channel-color-rgb), 0.4);
                        content: '';
                    }
                }
            }
        }
    }
}
