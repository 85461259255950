.button {
    position: absolute;
    top: 14px;
    right: 14px;
    box-sizing: border-box;
    padding: 0;
    border: none;
    margin: 10px;
    background: none;
    color: rgb(var(--time-webkit-color-secondary-rgb), 0.64);
    cursor: pointer;
    font-size: 0;
    line-height: 0;
    text-align: center;
}

.svg {
    width: 24px;
    height: 24px;
    box-sizing: border-box;
}

@media (max-width: 575px) {
    .button {
        top: 10px;
        right: 4px;
    }
}
