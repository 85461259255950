.alert {
    position: relative;
    overflow: hidden;
    border-radius: 8px;
}

.alert.medium::before {
    position: absolute;
    top: 0;
    left: 0;
    width: 4px;
    height: 100%;
    content: '';
}

.alert.info.medium::before {
    background-color: rgb(var(--time-webkit-color-main-rgb));
}

.alert.warning.medium::before {
    background-color: rgb(var(--time-webkit-color-away-indicator-rgb));
}

.alert.danger.medium::before {
    background-color: rgb(var(--time-webkit-color-error-rgb));
}

.contentWrapper {
    display: grid;
    grid-template-areas:
        'icon message button-close'
        'spacerLeft1 secondary-message spacerRight1'
        'spacerLeft2 body spacerRight2';
    grid-template-columns: auto 1fr auto;
    grid-template-rows: auto auto auto;
}

.contentWrapper.large {
    padding: 16px;
}

.contentWrapper.medium {
    padding: 12px;
    margin-left: 4px;
}

.contentWrapper.info {
    background-color: rgb(var(--time-webkit-color-main-rgb), 0.08);
    color: rgb(var(--time-webkit-color-main-rgb));
}

.contentWrapper.warning {
    background-color: rgb(var(--time-webkit-color-away-indicator-rgb), 0.12);
    color: rgb(var(--time-webkit-color-away-indicator-rgb));
}

.contentWrapper.danger {
    background-color: rgb(var(--time-webkit-color-error-rgb), 0.12);
    color: rgb(var(--time-webkit-color-error-rgb));
}

.contentWrapper.secondaryMessageOnly {
    grid-template-areas: 'icon secondary-message button-close';
}

.icon {
    align-self: center;
    margin-right: 12px;
    grid-area: icon;
}

.message {
    grid-area: message;
}

.secondaryMessage {
    margin-top: 4px;
    grid-area: secondary-message;
}

.contentWrapper.secondaryMessageOnly .secondaryMessage {
    margin-top: 0;
}

.buttonClose {
    align-self: flex-start;
    padding: 0;
    border: 0;
    margin-left: 12px;
    background: transparent;
    color: rgb(var(--time-webkit-color-secondary-rgb), 0.72);
    cursor: pointer;
    font-size: 0;
    grid-area: button-close;
    line-height: 0;
    transition-duration: 150ms;
    transition-property: background-color, border-color, box-shadow;
    transition-timing-function: ease-out;
}

.buttonClose:hover,
.buttonClose:active,
.buttonClose:focus {
    color: rgb(var(--time-webkit-color-secondary-rgb));
}

.body {
    margin-top: 12px;
    font-size: 0;
    grid-area: body;
    line-height: 0;
}
