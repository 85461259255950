.listWrapper {
    overflow-y: auto;
}

.errorWrapper {
    display: flex;
    min-height: 60px;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
}

.elevation {
    padding: 8px;
}

.optionFocused {
    padding: 8px 20px;
    margin: -8px -20px;
    background: rgba(var(--time-webkit-color-secondary-rgb), 0.08);
}
