.fadeAnimation {
    animation-duration: 150ms;
    animation-iteration-count: 1;
    animation-timing-function: linear;
}

.fadeIn {
    animation-name: fadeInOpacity;
}

.fadeOut {
    animation-fill-mode: forwards;
    animation-name: fadeOutOpacity;
}

@keyframes fadeInOpacity {
    0% { opacity: 0; }
    100% { opacity: 1; }
}

@keyframes fadeOutOpacity {
    0% { opacity: 1; }
    100% { opacity: 0; }
}
